import Vue from 'vue';
import App from './App.vue';
import router from './router';

import "src/assets/less/reset.css";
import "src/assets/less/common.less";

import i18n from "assets/i18n/i18n";

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
