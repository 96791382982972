//公司名称
const companyName = "大连香江食品有限公司";
//公司名称英文
const companyNameEN = "Dalian Xiang Jiang Food Co.,Ltd.";
//公司邮箱
const email = "XJFOOD@DALIANBEAN.COM";
//工厂地址
const factoryAddress = "大连金州区三十里堡街道卫国社区卫国屯496号";
//工厂地址英文
const factoryAddressEN = "No.496 Weiguotun, Weiguo Community, Sanshilipu St., Jinzhou Dist., Dalian, Liaoning, China";
//工厂电话
const factoryPhone = "+86-411-87360919";

export { companyName, companyNameEN, email, factoryAddress, factoryPhone, factoryAddressEN };