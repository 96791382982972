<template>
  <div>
    <describe-item 
      image="me" 
      align="left"
      :title="$t('contact.title')">
      <template v-for="w in contact">
        <label-content :word="w" :key="w.label" :type="`contact ${currentLocale}`"></label-content>
      </template>
    </describe-item>
   
  </div>
</template>

<script>
import word from "components/word";
import labelContent from "components/label-content";
import describeItem from "components/describe-item";
import { factoryPhone, email } from "assets/js/config";


export default {
  components: {
    describeItem,
    word,
    labelContent
  },
  data() {
    return {

      contact: [
        {
          label: this.$t("contact.factoryAddressLabel"),
          content: this.$t("contact.factoryAddressContent"),
        },
        {
          label: this.$t("contact.factoryPhoneLabel"),
          content: factoryPhone,
        },
        {
          label: this.$t("contact.officeAddressLabel"),
          content: this.$t("contact.officeAddressContent"),
        },
        {
          label: this.$t("contact.officePhoneLabel"),
          content: "+86-411-39210917",
        },
        {
          label: this.$t("contact.emailLabel"),
          content: email
        },
      ]

    }
  }
}
</script>

<style lang="less" scoped>

</style>