<template>
  <footer class="footer-wrapper">
      <div class="content-wrapper">
        <div class="company-name">
          <p :class="['company', currentLocale === 'en' && 'only-company']">{{companyName}}</p>
          <p class="english" v-show="currentLocale === 'zh'">{{companySubName}}</p>
        </div>
        <div class="company-info">
          <template v-for="w in infoGroup">
            <label-content class="white" :word="w" :key="w.label" :type="`footer ${currentLocale}`"></label-content>
          </template>
          <p>
            Copyright © {{currentYear}} {{companyName}} All Rights Reserved.
            <a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备2021005119号-1</a>
          </p>
        </div>
      </div>
    </footer>
</template>

<script>
import { companyNameEN, factoryPhone } from "assets/js/config";
import labelContent from "components/label-content";

export default {
  components: {
    labelContent
  },
  data() {
    return {
      currentYear: "",


      companyName: this.$t("footer.companyName"),
      companySubName: companyNameEN,
      infoGroup: [
        {
          label: this.$t("footer.addressLabel"),
          content: this.$t("contact.factoryAddressContent"),
        },
        {
          label: this.$t("footer.telephoneLabel"),
          content: factoryPhone
        },
      ]
      
    }
  },
  created() {
    //获取年份
    let date = new Date();
    this.currentYear = date.getFullYear();
  }
};
</script>

<style lang="less" scoped>
@import "~assets/less/variable.less";


.footer-wrapper {
  width: 100%;
  padding: 40px 0;
  background: @color-orange;
  box-sizing: border-box;

  .content-wrapper {
    text-align: left;
    color: @color-white;

    .company-name {
      .company {
        font-size: 32px;
        letter-spacing: 4px;

        &.only-company {
          letter-spacing: 0;
          // margin-bottom: 35px;
        }
      }

      .english {
        font-size: 22px;
        line-height: 40px;
      }
    }

    .company-info {
      margin-top: 20px;
      p {
        font-size: 14px;
        line-height: 24px;
      }
    }

    @media screen and (max-width: 1180px) {
      display:  none;
    }
  }
}

@media screen and (max-width: 700px) {
  .footer-wrapper {
    padding: 20px 15px;

    .content-wrapper {

      .company-name .company {
        font-size: 24px;
      }
  
      .company-name .english {
        font-size: 16px;
      }
  
      .company-info {
        margin-top: 15px;
  
        p {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
