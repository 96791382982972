<template>
  <div>
    <describe-item :title="$t('product.title')" :describe="$t('product.describe')"></describe-item>
    <div class="product-wrapper content-wrapper">
      <template v-for="(item, index) in productList">
        <div class="product-item" :key="index">
          <div class="product-item-img">
            <div :class="['img', item.className]"></div>
          </div>
          <p class="product-item-title">- {{ item.name }} -</p>
          <p class="product-item-title sub" v-show="item.subName">{{ item.subName }}</p>
        </div>
      </template>
    </div>

    <template v-for="item in bigProductList">
      <div class="product-group" :key="item.name">
        <img class="product-group-background" :src="item.image" alt="">
        <div class="product-group-info">
          <p>{{item.name}}</p>
          <p v-show="currentLocale === 'zh'">{{item.title}}</p>
        </div>
      </div>
    </template>

   
  </div>
</template>

<script>
import describeItem from "components/describe-item";
import BIG1 from "assets/images/u/product-big-01.jpg";
import BIG2 from "assets/images/u/product-big-02.jpg";
import BIG3 from "assets/images/u/product-big-03.jpg";

export default {
  components: {
    describeItem
  },
  data() {
    return {
      productList: [
        {
          name: this.$t("product.list.mungbean"),
          className: ""
        },
        {
          name: this.$t("product.list.soybean"),
          className: "yellow"
        },
        {
          name: this.$t("product.list.adzukibean"),
          className: "red"
        },
        {
          name: this.$t("product.list.blacksoybeangreen"),
          subName: this.$t("product.list.blacksoybeangreenSubname"),
          className: "qr"
        },
        {
          name: this.$t("product.list.blacksoybeanyellow"),
          subName: this.$t("product.list.blacksoybeanyellowSubname"),
          className: "black"
        },
        {
          name: this.$t("product.list.buckwheatkernel"),
          className: "qm"
        },
      ],
      bigProductList: [
        {
          title: "其他豆类",
          name: "OTHER BEANS",
          image: BIG1,
        },
        {
          title: "谷物类",
          name: "CEREALS",
          image: BIG2,
        },
        {
          title: "籽仁类",
          name: "SEEDS",
          image: BIG3,
        },
      ]
    };
  },
};
</script>

<style lang="less" scoped>
@import "~assets/less/variable.less";


.product-wrapper {
  display: grid;
  grid-template-columns: 33.333% 33.333% 33.333%;
  justify-items: center;

  .product-item {
    &-img {
      width: 280px;
      height: 280px;
      overflow: hidden;
      
      .img {
        background: url("~assets/images/product-sprites.jpg") -0px -0px no-repeat;
        background-size: calc(280px * 6);
        width: 100%;
        height: 100%;


        &.qr {
          background-position-x: -280px;
        }

        &.yellow {
          background-position-x: -560px;
        }

        &.red {
          background-position-x: -840px;
        }

        &.black {
          background-position-x: -1120px;
        }

        &.qm {
          background-position-x: -1400px;
        }
      }


      &:hover {
	      -webkit-animation: shadow-drop-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: shadow-drop-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
    }

    &-title {
      font-size: 24px;
      text-align: center;
      margin: 12px 0;

      &.sub {
        font-size: 16px;
        color: @font-gray;
      }
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 50% 50%;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 100%;

    .product-item-img {
      width: 240px;
      height: 240px;
    }

    .product-item-title {
      font-size: 20px;
    }
  }
}

.product-group {
  width: 800px;
  height: 400px;
  position: relative;
  overflow: hidden;
  margin: 40px auto;

  &-background {
    width: 100%;
    object-fit: contain;
  }

  &-info {
    width: 100%;
    position: absolute;
    top: 50%;
    color: @color-white;
    transform: translateY(-50%);
    text-align: center;

    p {
      font-size: 54px;
      line-height: 1.2;
      transition: all .3s linear;
    }
  }

  &:hover {
    p {
      text-shadow: 4px 3px @font-black;
    }
  }

  @media screen and (max-width: 768px) {
    width: 600px;
    height: 300px;

    &-info {
      p {
        font-size: 42px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    width: 90%;
    height: auto;

    &-info {
      p {
        font-size: 28px;
      }
    }
  }

}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-10 12:32:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-drop-bottom
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-bottom {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.6);
            box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.6);
  }
}
@keyframes shadow-drop-bottom {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.6);
            box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.6);
  }
}

</style>