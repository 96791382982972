<template>
  <div>
    <div class="title-slogn">
      <div class="title-slogn-border" v-if="currentLocale === 'zh'">
        <span>{{ title.slice(0, -1) }}</span>
        <span>{{ title.slice(-1) }}</span>
      </div>
      <span class="english-title" v-else>{{ title }}</span>
    </div>
    <div class="title-slogan-desc" v-show="currentLocale === 'zh'">
      {{slogan}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slogan: {
      type: String,
    },
    title: {
      type: String,
      require: true,
    },
  },
};
</script>

<style scoped lang="less">
@import "~assets/less/variable.less";

.title-slogn {
  margin-bottom: 15px;

  &-border {
    display: inline-block;
    border-bottom: 2px solid @color-white;
    padding-bottom: 15px;
  }

  span {
    display: inline-block;
    font-size: 94px;
    font-weight: bold;
    text-shadow: 4px 3px 4px @font-black;
    letter-spacing: 15px;

    &:last-child {
      letter-spacing: 0;
    }

    &.english-title {
      font-size: 140px;
    }
  }

}

.title-slogan-desc {
  color: @color-white;
  font-size: 32px;
  letter-spacing: 1px;
  text-shadow: 4px 3px 4px @font-black;
}

//英文兼容ipad
@media screen and (max-width: 900px) {
  .title-slogn span {
    &.english-title {
      font-size: 100px;
    }
  }
}

//兼容小屏幕
@media screen and (max-width: 700px) {
  .title-slogn span {
    font-size: 48px;

    &.english-title {
      font-size: 62px;
    }
  }

  .title-slogan-desc {
    font-size: 24px;
  }
}

</style>
