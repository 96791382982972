import { factoryAddressEN, companyNameEN } from "assets/js/config";


export const en = {
  //目录
  nav: {
    aboutus: "About Us",
    service: "Our Service",
    product: "Our Product",
    contactus: "Contact Us",
    bean: "Sprouted Beans",
    procurement: "Procurement Services"
  },

  //认证
  certification: {
    title: "Certification",
    describe: [
      "Xiang Jiang Food has consistently received certification from BRC Food Standards, obtained organic certification from the EU, USA, Korea, and Taiwan, and has also registered for SEDEX Social Responsibility Certification.",
      "Our laboratory tests all raw materials and finished products. It regularly commissions third-party testing organizations such as EUROFINS, SGS, and Huatest to conduct product testing."
    ]
  },
  
  //关于我们
  aboutus: {
    company: {
      title: "About Us",
      describe: [
        "Dalian Xiang Jiang Food Co., Ltd. is a comprehensive enterprise specializing in cultivation,",
        "processing, storage, as well as import and export trade of beans.",
        "The core of our company is a commitment to keeping our word while providing high-quality products and services to our customers.",
        "In the future, we will continue to focus on grain food processing, develop additional products,",
        "provide people with healthy and safe meals of mixed grains by innovating our technology, upgrading our products,",
        "whileimproving our quality and efficiency."
      ]
    },
    factory: {
      title: "Factory",
      describe: [
        "Our factory covers an area of 20,000 square meters.",
        "Its processing workshop measures 3,000 square meters and a storage facility is 6,000 square meters.",
        "We have installed advanced and specialized bean processing machinery and equipment that",
        "enhances our production capacity, technology, and efficiency.",
        "Our main processing equipment includes:",
        "color sorters, X-ray machines, magnetic separators, grain separators, de-stoners, belt sorters, polishers,",
        "specific gravity machines, conveyor belt hand sorters, metal detectors, and vacuum bag packing equipment."
      ]
    }
  },

  //采购服务
  procurement: {
    title: "Procurement Services",
    describe: [
      "Xiang Jiang Food has 30 years of procurement experience and has accumulated a strong network of origins.",
      "We provide customers with the highest-quality products and services according to their needs.",
      "We follow the latest developments of origins and promptly communicate with our customers.",
      "We strictly control quality duringprocurement and",
      "have established an efficienttraceability system to guarantee our products' quality and safety,",
      "which enhances our company's competitiveness in its domestic and international markets."
    ]
  },

  //我们的产品
  product: {
    title: "Our Product",
    describe: [
      "Xiang Jiang Food mainly sells beans, grains, and seeds, with a focus on sprouting and organic food.",
      "We export our products to many countries and regions such as the United States, United Kingdom, Japan, Korea, the Netherlands, Hong Kong, Macau, and Taiwan.",
    ],
    list: {
      mungbean: "Mung Bean",
      soybean: "Soy Bean",
      adzukibean: "Adzuki Bean",
      blacksoybeangreen: "Black Soybean",
      blacksoybeangreenSubname: "(with Green Kernels)",
      blacksoybeanyellow: "Black Soybean",
      blacksoybeanyellowSubname: "(with Yellow Kernels)",
      buckwheatkernel: "Buckwheat Kernel"
    }
  },

   //芽豆
   bean: {
    bean: {
      title: "Sprouted Beans",
      describe: [
        "Xiang Jiang Food mainly sells sprouted mung beans, soy beans, and dried black beans.",
        "We have the industry's most efficient procurement network.",
        "In our factory, we use advanced equipment to conduct multiple rounds of screening,",
        "manual selection, and rigorous testing that enables us to produce high-quality products."
      ]
    },
    product: {
      title: "Product",
      describe: [
        "Sprouting beans with moisture between 12% and 14%",
        "Germinated mung bean sprouting rate of up to 99%",
        "Germinated soy bean sprouting rate of up to 98%",
        "Sprouting rate of dried black beans of up to 98%"
      ]
    }
  },

  //联系我们
  contact: {
    title: "Contact Us",
    factoryAddressLabel: "Factory Address",
    factoryAddressContent: factoryAddressEN,
    factoryPhoneLabel: "Factory Telephone",
    officeAddressLabel: "Office Address",
    officeAddressContent: "Room A1305, Kailun International Building, Wucaicheng Block A-1, Dalian Economic and Technological Development Zone, Dalian, Liaoning, China",
    officePhoneLabel: "Office Telephone",
    emailLabel: "Email",
  },

  //底部信息
  footer: {
    companyName: companyNameEN,
    addressLabel: "Address",
    telephoneLabel: "Telephone"
  },

  //首页服务
  service: {
    title1: "Quality",
    title2: "Sincertity",
    title3: "Professional",
    title4: "Passion",
  },

  languageTitle: "language",

}