<template>
  <div>
    <describe-item 
      image="purchase" 
      :title="$t('procurement.title')" 
      :describe="$t('procurement.describe')"></describe-item>
  </div>
</template>

<script>
import describeItem from "components/describe-item";

export default {
  components: {
    describeItem
  }
}
</script>

<style lang="less" scoped>

</style>