import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { zh } from "./langs/zh";
import { en } from "./langs/en";

Vue.use(VueI18n);

//解析多语言
let host = window.location.hostname.split(".").shift();
let locale = 'zh';
if (['www', 'en'].includes(host)) {
  locale = host === 'www' ? 'zh' : host;
}

const messages = {
  en,
  zh
}

const i18n = new VueI18n({
  locale,
  messages
})

Vue.prototype.currentLocale = locale;


export default i18n;