<template>
  <div>
    <div v-show="image" :class="['img-introduce', image]"></div>
    <div class="content-wrapper">
      <div class="home-info">
        <word :title="title" :describe="describe" :align="align">
          <slot></slot>
        </word>
      </div>
    </div>
  </div>
</template>

<script>
import word from "components/word";

export default {
  components: {
    word
  },
  props: {
    image: {
      type: String,
      default: ""
    },
    title: {
      type: String
    },
    describe: {
      type: String | Array
    },
    align: {
      type: String
    }
  },
};
</script>

<style scoped lang="less">

  @import "~assets/less/variable.less";

  .img-introduce {
    width: 100%;
    height: 550px;
    background-position: center 60%;
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-size: cover;

    &.company {
      background-position: 0 65%;
      background-image: url("~assets/images/company.jpg");

      @media screen and (min-width: 1500px) {
        background-position: center 70%;
      }

      @media screen and (max-width: 700px) and (min-width: 400px) {
        background-position: 15% 0;
      }

      @media screen and (max-width: 400px) {
        background-position: 21% center;
      }
    }

    &.workhouse {
      background-position: center 50%;
      background-image: url("~assets/images/company001.jpg");
    }

    &.purchase {
      background-position: 20% center;
      background-image: url("~assets/images/purchase.jpg");
    }

    &.bean {
      background-position: 60% 15%;
      background-image: url("~assets/images/service001.jpg");
    }

    &.product {
      background-position: center 15%;
      background-image: url("~assets/images/service002.jpg");
    }

    &.me {
      // background-position: center 35%;
      background-image: url("~assets/images/company002.jpg");
    }

    @media screen and (max-width: 700px) {
      &.workhouse {
        background-position: 82% 0;
      }
    }
  }

  .home-info {
    padding: 50px 20px;

    // &.small {
    //   padding: 30px 20px 50px;
    // }

    @media screen and (max-width: 700px) {
      padding: 50px 15px;

      // &.small {
      //   padding: 30px 15px 50px;
      // }
    }
  }

</style>
