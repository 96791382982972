<template>
  <div class="word-wrapper">
    <span :class="['word-label', type]">{{word.label}}</span>
    ：
    <span>{{word.content}}</span>
  </div>
</template>

<script>
export default {
  props: {
    word: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
      default: ""
    }
  },
};
</script>

<style scoped lang="less">

  @import "~assets/less/variable.less";

  .word-wrapper {
    color: @font-gray;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
    display: flex;

    .word-label {
      &.contact {
        flex: 0 0 160px;
        &.zh {
          flex: 0 0 95px;
        }
      }

      &.footer {
        flex: 0 0 80px;

        &.zh {
          flex: 0 0 40px;
        }
      }
    }

    &.white {
      color: @color-white;
      font-size: 14px;
      line-height: 24px;
    }

    @media screen and (max-width: 700px) {
      font-size: 12px;
      line-height: 24px;

      .word-label {
        &.contact {
          flex: 0 0 120px;

          &.zh {
            flex: 0 0 70px;
          }
        }
      }
    }
  }
</style>
