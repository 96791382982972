import { companyName, factoryAddress } from "assets/js/config";

export const zh = {

  //目录
  nav: {
    aboutus: "关于我们",
    service: "我们的服务",
    product: "我们的产品",
    contactus: "联系我们",
    bean: "芽豆",
    procurement: "采购服务"
  },

  languageTitle: "多语言",

  //认证
  certification: {
    title: "认证 CERTIFICATION",
    describe: [
      "香江食品连续通过了BRC 食品标准认证和美国、欧盟、韩国、台湾有机认证，并注册了SEDEX社会责任认证。",
      "我们的实验室会对所有原料和成品进行检测，并且定期委托EUROFINS、SGS和华测等第三方检测机构做产品检测。"
    ]
  },
  
  //关于我们
  aboutus: {
    company: {
      title: "公司介绍",
      describe: [
        `${companyName}，是专业经营各种豆类食品，集种植，加工，仓储，和进出口贸易于一体的综合性企业。`,
        "自成立以来，一直把守信和为客户提供高品质产品以及服务作为公司的核心。",
        "未来，我们将继续聚集于粮食食品加工，开发更多的产品项目，通过技术创新，产品升级，提高质效的同时",
        "为百姓提供健康，安全的杂粮膳食。"
      ]
    },
    factory: {
      title: "工厂介绍",
      describe: [
        "工厂占地面积20,000平方米。加工车间面积3,000平方米。仓储面积6,000平方米。",
        "引进先进及专业的豆类加工机械设备，以提升我们生产能力、技术水平、生产效率。",
        "主要加工设备：色选机，X光机，磁选机，分粒机，去石机，带选机，磨光机，比重机，输送带手选设备，",
        "金属探测仪，真空袋包装设备。"
      ]
    }
  },

  //采购服务
  procurement: {
    title: "采购服务",
    describe: [
      "香江食品拥有30年的采购经验，并积累了优质的产地资源。",
      "根据客户的需求为客户提供最高品质的产品和服务。我们时时关注产地最新动态并及时与客户沟通。",
      "我们在采购环节上，严把质量关，建立完善的质量溯源体系，保障产品的质量安全，",
      "增强了公司在国内外市场的竞争力。"
    ]
  },

  //联系我们
  contact: {
    title: "联系我们",
    factoryAddressLabel: "工厂地址",
    factoryAddressContent: factoryAddress,
    factoryPhoneLabel: "工厂电话",
    officeAddressLabel: "办公室地址",
    officeAddressContent: "大连市经济技术开发区五彩城A区1栋凯伦国际大厦A1305室",
    officePhoneLabel: "办公室电话",
    emailLabel: "邮箱",
  },

  //底部信息
  footer: {
    companyName: companyName,
    addressLabel: "地址",
    telephoneLabel: "电话"
  },

  //我们的产品
  product: {
    title: "我们的产品",
    describe: [
      "香江食品主要经营各种豆类、谷物类和籽仁类等产品，尤其在发芽和有机食品领域较为出众，",
      "产品远销美国、英国、日本、韩国、荷兰、港澳台等全球各个国家和地区。",
    ],
    list: {
      mungbean: "绿豆",
      soybean: "黄豆",
      adzukibean: "红豆",
      blacksoybeangreen: "青仁乌豆",
      blacksoybeangreenSubname: "",
      blacksoybeanyellow: "黑大豆",
      blacksoybeanyellowSubname: "",
      buckwheatkernel: "荞麦",
    }
  },

  //首页服务
  service: {
    title1: "民以食为天",
    slogan1: "精挑严选，取之于民",
    title2: "食以安为先",
    slogan2: "严格管理，安全生产",
    title3: "安以质为本",
    slogan3: "保障品质，精益求精",
    title4: "质以诚为根",
    slogan4: "诚信经营，用之于民",
  },

  //芽豆
  bean: {
    bean: {
      title: "芽豆",
      describe: [
        "在芽豆方面，香江食品主要是经营发芽绿豆，黄豆和青仁乌豆",
        "我们拥有最优质的货源网络，经过工厂先进设备的多重筛选，人工手选和严谨的试验测试",
        "生产出优质的产品"
      ]
    },
    product: {
      title: "产品数据",
      describe: [
        "芽豆水分在12% - 14%之间",
        "发芽黄豆芽率高达99%",
        "发芽绿豆芽率高达98%",
        "发芽青仁乌豆芽率高达98%"
      ]
    }
  },
}