import { render, staticRenderFns } from "./service-slogan.vue?vue&type=template&id=1278e650&scoped=true"
import script from "./service-slogan.vue?vue&type=script&lang=js"
export * from "./service-slogan.vue?vue&type=script&lang=js"
import style0 from "./service-slogan.vue?vue&type=style&index=0&id=1278e650&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1278e650",
  null
  
)

export default component.exports