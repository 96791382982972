import Vue from 'vue'
import VueRouter from 'vue-router'
import about from '../views/About.vue';
import contact from '../views/Contact.vue';
import product from '../views/product.vue';
import service from '../views/service.vue';
import purchase from '../views/purchase.vue';
import BeanSprout from '../views/bean-sprout.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
    // component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/',
    name: 'service',
    component: service
    // component: () => import(/* webpackChunkName: "about" */ '../views/service.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: product
    // component: () => import(/* webpackChunkName: "about" */ '../views/product.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: purchase
    // component: () => import(/* webpackChunkName: "about" */ '../views/product.vue')
  },
  {
    path: '/bean-sprout',
    name: 'bean-sprout',
    component: BeanSprout
    // component: () => import(/* webpackChunkName: "about" */ '../views/product.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
