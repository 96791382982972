import { render, staticRenderFns } from "./bean-sprout.vue?vue&type=template&id=1a492e6e&scoped=true"
import script from "./bean-sprout.vue?vue&type=script&lang=js"
export * from "./bean-sprout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a492e6e",
  null
  
)

export default component.exports