<template>
  <div :class="align">
    <div class="title-group" :style="letterStyle">
      <span>{{ title.slice(0, -1) }}</span>
      <span>{{ title.slice(-1) }}</span>
    </div>

    <div class="word-desc">
      <template v-if="describe && describe.length">
        <p v-for="t in describe" :key="t">{{t}}</p>
      </template>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "center",
    },
    title: {
      type: String,
      require: true,
    },
    describe: {
      type: Array
    },
    letterSpace: {
      type: String
    }
  },
  computed: {
    letterStyle() {
      if (this.letterSpace) {
        return `letter-spacing: ${this.letterSpace}px`
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "~assets/less/variable.less";

.title-group {
  margin-bottom: 20px;

  display: inline-block;
  border-bottom: 2px solid @font-gray;
  padding-bottom: 8px;
  letter-spacing: 5px;

  span {
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    

    &:last-child {
      letter-spacing: 0;
    }
  }
}

.word-desc {
  p {
    color: @font-gray;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
  }
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

//兼容手机
@media screen and (max-width: 700px) {

  .title-group span {
    font-size: 24px;
  }

  .word-desc p {
    font-size: 14px;
  }

  // .left {
  //   text-align: center;
  // }
}
</style>
